<template>
  <v-container fluid class='align-self-sm-stretch justify-space-between'>
    <v-col class='pa-0 py-3'>
      <v-tooltip bottom>
        <template #activator='{ on }'>
          <v-icon v-on='on' @click='$emit("close")'>
            mdi-arrow-left-circle
          </v-icon>
        </template>
        {{ $t('BACK') }}
      </v-tooltip>
    </v-col>
    <v-col>
      <v-row>
        <span class='subtitle-2'>{{$t("STOCK_TYPE_DESCRIPTION")}}</span>
      </v-row>
      <v-row>
        <v-select
          v-model='documentType'
          :items='items'
          item-value='text'
          :label='$t("DOCUMENT_TYPE")'
        />
      </v-row>
    </v-col>
    <v-col class='card-footer'>
      <v-btn
        color='secondary'
        dark
        @click='applyFilter'
      >
        {{ $t('SAVE') }}
      </v-btn>
    </v-col>
  </v-container>
</template>
<script>
  import axios from 'axios';

  export default {
    data () {
      return {
        text: null,
        items: [],
        documentType: '',
      };
    },
    mounted: function () {
      this.getAllType();
    },
    methods: {
      applyFilter () {
        this.$emit('onFilter', { text: this.text });
        this.$emit('applyFilters');
      },

      getAllType: async function () {
        try {
          this.$store.dispatch('loading/toggleLoading');
          const result = await axios({
            url: '/stock-type',
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: 'GET',
            responseType: 'json',
            withCredentials: true,
          });
          this.items = result.data;
          this.$store.dispatch('loading/toggleLoading');
        } catch (e) {
          console.log(e); /* eslint-disable-line no-console */
          this.$store.dispatch('loading/toggleLoading');
        }
      },
    },
  };

</script>
<style>
  .selected-chip {
    border-color: #0488b9 !important;
    color: #FFFFFF !important;
    background-color: #FFFFFF !important;
  }
  .selected-chip span {
    color: #0488b9 !important;
  }
  .card-footer {
    text-align: end;
  }
</style>
